@import "minima";

th,
td {
  padding: 0.5em;
}

.table {
  border: 1px solid;
  margin-bottom: 15px;
  padding: 0.5em;
}

.centre {
  text-align: center;
}

.clear {
  clear: both;
}

.wrap-image {
  img {
    display: block;
    margin: 0 auto;
  }

  @media(min-width: 576px) {
    float: left;
    padding-right: 2em;
  }
}

.strong-em {
  color: red;
}

.alert {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  // border-color: #f5c6cb;
  border-radius: .25rem;
  padding: .75rem 1.25rem;

  a {
    color: #721c24;
  }
}

@font-face {
  font-family: 'noto_sansregular';
  src: url('/assets/fonts/notosans-regular-webfont.woff2') format('woff2'),
       url('/assets/fonts/notosans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'noto_sansblack';
  src: url('/assets/fonts/notosans-black-webfont.woff2') format('woff2'),
       url('/assets/fonts/notosans-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'oswaldbold';
  src: url('/assets/fonts/oswald-bold-webfont.woff2') format('woff2'),
       url('/assets/fonts/oswald-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.covid-infographic {
  font-size: 1.5rem;

  section {
    padding: 1em;
    display: flex;
    align-items: center;
  }

  section:nth-child(odd) {
    background-color: #96e2e2;
  }

  section:nth-child(odd):not(:nth-child(1)) {
    flex-direction: row-reverse;
  }

  section:nth-child(even) {
    background-color: #b9ebeb;
  }

  ul {
    margin-bottom: 0;
  }

  h1, h2 {
    text-transform: uppercase;
  }

  h1 {
    font-family: noto_sansregular;
    font-size: 1.5em;
    line-height: 1.4em;
    white-space: pre-line;
    margin-bottom: 0;

    b {
      font-family: noto_sansblack;
      font-size: 1.5em;
    }
  }

  h2 {
    font-family: oswaldbold;
    letter-spacing: .08em;
  }
}

#booking-link {
  background-color: #007bff;
  color: white;
  padding: 0.5em;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  border: none;
  border-radius: 0.3em;
}
